import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { getIsDarkThemeFromBackgroundHexColor } from 'src/utils/colors/color-conversion';
import { wrapper_ } from './Background.module.scss';

export const Background = forwardRef(function Background(
  { background, className, children, as = 'div', isDarkTheme = false, style, ...rest },
  ref
) {
  const Wrapper = as;
  const useDarkTheme = isDarkTheme || getIsDarkThemeFromBackgroundHexColor(background);
  const themeClass = useDarkTheme ? 'ws-content-dark-theme' : 'ws-content-default-theme';
  return (
    <Wrapper
      style={{ ...background ? { '--background': background } : {}, ...style }}
      ref={ref}
      className={classNames(wrapper_, background && themeClass, className)}
      {...rest}
    >
      {children}
    </Wrapper>
  );
});

Background.propTypes = {
  background: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  className: PropTypes.string,
  children: PropTypes.node,
  isDarkTheme: PropTypes.bool,
  style: PropTypes.shape({}),
};
