import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { accordion_, isOpen_, container_ } from './accordion.module.scss';

export const Accordion = ({ id, className, innerClassName, isOpen = false, children, ...rest }) => {
  return (
    <div id={id} className={classNames(accordion_, isOpen && isOpen_, className)} {...rest}>
      <div className={classNames(container_, innerClassName)}>{children}</div>
    </div>
  );
};

Accordion.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
