import React from 'react';
import { classNames } from 'src/utils/css';
import PropTypes from 'prop-types';
import { RefreshGrid, RefreshCell, GRID_DEFAULT_CONFIG } from 'src/components/layout/Grid';
import { Subsection } from 'src/components/layout/Subsection';
import { FeatureBlockDetailsListItem } from 'src/components/common/FeatureBlockDetailsListItem';
import {
  pointItem_,
  pointItemCell_,
  pointItemWithSeparators_,
  pointItemCentered_,
  pointItemHeading_,
  pointItemPreHeading_,
} from './KeyPoints.module.scss';

const PointItem = ({
  heading,
  subheading,
  preHeading,
  details,
  className,
  headingClassName,
  detailsClassName,
  headingFluidTextConfig,
  detailsFluidTextConfig,
  ...rest
}) => (
  <RefreshCell md={4} className={classNames(pointItemCell_, className)}>
    <FeatureBlockDetailsListItem
      className={pointItem_}
      heading={heading}
      subheading={subheading}
      preHeading={preHeading}
      details={details}
      preHeadingClassName={pointItemPreHeading_}
      headingClassName={classNames(pointItemHeading_, headingClassName)}
      detailsClassName={detailsClassName}
      headingConfig={headingFluidTextConfig}
      detailsConfig={detailsFluidTextConfig}
      {...rest}
    />
  </RefreshCell>
);

export const KeyPoints = ({
  list,
  withSeparators = false,
  centeredItems = false,
  itemHeadingFluidTextConfig,
  itemDetailsFluidTextConfig,
  className,
  itemClassName,
  itemHeadingClassName,
  itemDetailsClassName,
  ...props
}) => {
  return (
    <Subsection {...props} subsectionName="KeyPoints">
      <div className={className}>
        <RefreshGrid xs={{ columnGap: 0 }} md={{ columnGap: GRID_DEFAULT_CONFIG.gap }}>
          {list.map(
            ({
              heading: _heading,
              subheading: _subheading,
              preHeading,
              details,
              entryId,
              ...rest
            }) => (
              <PointItem
                key={entryId || _heading}
                heading={_heading}
                subheading={_subheading}
                preHeading={preHeading}
                details={details}
                className={classNames(
                  itemClassName,
                  withSeparators && pointItemWithSeparators_,
                  centeredItems && pointItemCentered_
                )}
                headingClassName={itemHeadingClassName}
                detailsClassName={itemDetailsClassName}
                headingFluidTextConfig={itemHeadingFluidTextConfig}
                detailsFluidTextConfig={itemDetailsFluidTextConfig}
                entryId={entryId}
                {...rest}
              />
            )
          )}
        </RefreshGrid>
      </div>
    </Subsection>
  );
};

KeyPoints.propTypes = {
  /** The key point items themselves, usually 3 of them */
  list: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      subheading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      details: PropTypes.string,
      entryId: PropTypes.string,
    })
  ).isRequired,
  /** Horizontal borders between key point items */
  withSeparators: PropTypes.bool,
  /** Centered text (xs and sm breakpoints) for key point items */
  centeredItems: PropTypes.bool,
  /** FluidText component config for key point item headings.
   *
   * Default value comes from FeatureBlockDetailsListItem. */
  itemHeadingFluidTextConfig: PropTypes.shape({}),
  /** FluidText component config for key point item details.
   *
   * Default value comes from FeatureBlockDetailsListItem. */
  itemDetailsFluidTextConfig: PropTypes.shape({}),
  className: PropTypes.string,
  headingClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  itemHeadingClassName: PropTypes.string,
  itemDetailsClassName: PropTypes.string,
};
