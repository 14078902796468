import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { toFieldPath } from '@stackbit/annotations';
import { classNames } from 'src/utils/css';
import { asSubsection } from 'src/components/layout/Subsection';
import { useComponentContext } from 'src/components/layout/ComponentContext';
import { FluidText } from 'src/components/common/FluidText';
import { MARKDOWN_MODES } from 'src/tapestry/core/markdown';
import {
  wrapper_,
  wrapperWithSeparator_,
  heading_,
  subheading_,
  alignCenter_,
  alignLeft_,
} from './SectionHeading.module.scss';

const alignClasses = {
  center: alignCenter_,
  left: alignLeft_,
};

const SectionHeading = asSubsection('SectionHeading')(
  ({
    heading,
    subheading,
    textAlign = 'center',
    withSeparator = false,
    className,
    headingClassName,
    subheadingClassName,
    children,
    ...rest
  }) => {
    const headingClassNames = classNames(heading_, headingClassName);
    const subheadingClassNames = classNames(subheading_, subheadingClassName);

    const { entryId } = useComponentContext();
    const headerAnnotation = entryId
      ? toFieldPath({ objectId: entryId, fieldPath: 'sectionHeading' })
      : {};
    const subheadingAnnotation = entryId
      ? toFieldPath({ objectId: entryId, fieldPath: 'sectionBody' })
      : {};

    const headingContent = isString(heading) ? (
      <FluidText className={headingClassNames} type="h2" {...headerAnnotation}>
        {heading}
      </FluidText>
    ) : (
      React.Children.map(heading, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            className: classNames(headingClassNames, child.props.className),
            ...headerAnnotation,
          });
        }
        return child;
      })
    );
    const subheadingContent = isString(subheading) ? (
      <FluidText className={subheadingClassNames} type="markdown" markdownProps={{ mode: MARKDOWN_MODES.inlineText }} {...subheadingAnnotation}>
        {subheading}
      </FluidText>
    ) : (
      React.Children.map(subheading, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            className: classNames(subheadingClassNames, child.props.className),
            ...subheadingAnnotation,
          });
        }
        return child;
      })
    );

    return (
      <div
        className={classNames(
          wrapper_,
          alignClasses[textAlign],
          withSeparator && wrapperWithSeparator_,
          className
        )}
        {...rest}
      >
        {headingContent}
        {subheadingContent}
        {children}
      </div>
    );
  }
);

SectionHeading.propTypes = {
  heading: PropTypes.node,
  subheading: PropTypes.node,
  textAlign: PropTypes.oneOf(['left', 'center']),
  withSeparator: PropTypes.bool,
  className: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
};

SectionHeading.displayName = 'SectionHeading';

export { SectionHeading };
