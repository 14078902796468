'use client';

import React from 'react';
import PropTypes from 'prop-types';
import { MARKDOWN_MODES, Markdown } from 'src/tapestry/core/markdown';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { withLocaleCheckboxes } from 'src/components/contentful/withLocaleCheckboxes';
import { withFramework } from 'lib/get-framework';
import { NotificationBanner } from './NotificationBanner.component';

const ContentfulNotificationBannerContent = ({
  contentfulId,
  body,
  backgroundColor,
  ...props
}) => {
  const { getStringWithGlobals } = useTranslationContext();

  const id = withFramework({
    gatsby: () => contentfulId,
    next: () => props.sys.id,
  });
  const _body = withFramework({
    gatsby: () => body?.body,
    next: () => body,
  });

  if (!id && !_body) {
    return null;
  }

  return (
    <NotificationBanner
      id={id}
      entryId={id}
      background={backgroundColor}
      {...props}
    >
      <Markdown source={getStringWithGlobals(_body)} mode={MARKDOWN_MODES.inlineText} />
    </NotificationBanner>
  );
};

ContentfulNotificationBannerContent.propTypes = {
  contentfulId: PropTypes.string,
  body: PropTypes.shape({
    body: PropTypes.node,
  }),
  backgroundColor: PropTypes.string,
};

export const ContentfulNotificationBanner = withLocaleCheckboxes(ContentfulNotificationBannerContent);
