'use client';

import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { asSubsection } from 'src/components/layout/Subsection';
import { FluidText } from 'src/components/common/FluidText';
import { classNames } from 'src/utils/css';
import {
  hero,
  heroFullHeight_,
  wideMedia_,
  childContainer,
  textContainer,
  subheadingContainer_,
  mediaContainer,
  ctaContainer_,
  ctaContainerInner_,
  widthBasedMediaAspectRatio_,
} from './SplitHero.module.scss';

const SplitHero = asSubsection('SplitHero')(
  ({
    heading,
    subheading,
    cta,
    secondaryCta,
    className,
    media,
    children,
    eyebrow,
    widthBasedMediaAspectRatio = false,
    hasWideMedia,
    isFullHeight = false,
    ...rest
  }) => {
    const headingContent = isString(heading) ? <FluidText type="h1">{heading}</FluidText> : heading;

    const subheadingContent = isString(subheading) ? (
      <div className={classNames(subheadingContainer_)}>
        <FluidText type="p">{subheading}</FluidText>
      </div>
    ) : (
      React.cloneElement(subheading, {
        className: classNames(subheadingContainer_, subheading.props.className),
      })
    );

    const eyebrowContent = isString(eyebrow) ? (
      <FluidText type="p" min="ws-eyebrow-sm" max="ws-eyebrow-md" uppercase>
        {eyebrow}
      </FluidText>
    ) : (
      eyebrow
    );

    return (
      <div className={classNames(hero, className, isFullHeight && heroFullHeight_, hasWideMedia && wideMedia_)} {...rest}>
        <div className={classNames(childContainer, textContainer)}>
          {eyebrowContent}
          {headingContent}
          {subheadingContent}
          {(cta || secondaryCta) && (
            <div className={ctaContainer_}>
              <div className={ctaContainerInner_}>
                {cta}
                {secondaryCta}
              </div>
            </div>
          )}
          {children}
        </div>
        <div
          className={classNames(
            childContainer,
            mediaContainer,
            widthBasedMediaAspectRatio && widthBasedMediaAspectRatio_
          )}
        >
          {media}
        </div>
      </div>
    );
  }
);

SplitHero.propTypes = {
  eyebrow: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subheading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  cta: PropTypes.node,
  secondaryCta: PropTypes.node,
  className: PropTypes.string,
  media: PropTypes.node,
  children: PropTypes.node,
  widthBasedMediaAspectRatio: PropTypes.bool,
  isFullHeight: PropTypes.bool,
};

SplitHero.displayName = 'SplitHero';

export { SplitHero };
