import React, { useContext, useState, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import { cookieGet, cookieSet } from 'src/utils/cookies';
import { numberGreaterThanZeroNotMoreThanOne } from 'src/utils/prop-types';
import { EVENT_NAMES, trackAnalyticsEvent } from 'src/services/analytics-service';

const DismissibleContext = React.createContext({});
const useDismissibleContext = () => useContext(DismissibleContext);
DismissibleContext.displayName = 'DismissibleContext';

const DismissibleContextProvider = ({
  id,
  componentName = 'Dismissible',
  children,
  displayedEvent = EVENT_NAMES.DISMISSIBLE_DISPLAYED,
  dismissedEvent = EVENT_NAMES.DISMISSIBLE_DISMISSED,
  shouldAutoOpen = true,
  sampleRate = 1,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEligibleToOpen, setIsEligibleToOpen] = useState(false);
  const componentContextForEvents = { name: componentName };

  const cookieKey = `dismissible-${id}-dismissed`;

  const openHandler = () => {
    setIsOpen(true);
    trackAnalyticsEvent(displayedEvent, { label: id }, componentContextForEvents);
  };

  const closeHandler = () => {
    setIsOpen(false);
    trackAnalyticsEvent(dismissedEvent, { label: id }, componentContextForEvents);
    cookieSet(cookieKey, 1);
  };

  useEffect(() => {
    // Assumed that consumers will be calling `openHandler()` themselves
    // if they have set `shouldAutoOpen = false`
    if (isEligibleToOpen && shouldAutoOpen) {
      openHandler();
    }
  }, [isEligibleToOpen, shouldAutoOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if (!cookieGet(cookieKey) && Math.random() < sampleRate) {
      setIsEligibleToOpen(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DismissibleContext.Provider
      value={{
        id,
        closeHandler,
        openHandler,
        isOpen,
        isEligibleToOpen,
      }}
    >
      {children}
    </DismissibleContext.Provider>
  );
};

DismissibleContextProvider.propTypes = {
  id: PropTypes.string,
  componentName: PropTypes.string,
  children: PropTypes.node,
  displayedEvent: PropTypes.string,
  dismissedEvent: PropTypes.string,
  shouldAutoOpen: PropTypes.bool,
  sampleRate: numberGreaterThanZeroNotMoreThanOne,
};

export { DismissibleContextProvider, useDismissibleContext };
