import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { toFieldPath, toObjectId } from '@stackbit/annotations';
import { classNames } from 'src/utils/css';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { MARKDOWN_MODES } from 'src/tapestry/core/markdown';
import { FluidText } from '../FluidText';
import { heading_, subheading_ } from './FeatureBlockDetailsListItem.module.scss';

const RENDERER_CONFIG = (finalDetailsConfig, detailsClassName) => ({
  paragraph: ({ children }) => (
    <FluidText type="p" {...finalDetailsConfig} className={detailsClassName}>
      {children}
    </FluidText>
  ),
  link: ({ href, children }) => {
    const isPDF = href.indexOf('.pdf') > 0;
    if (isPDF) {
      return (
        <a href={href} target="_blank" rel="noreferrer">
          {children}
        </a>
      );
    }
    return <SmartLink href={href}>{children}</SmartLink>;
  },
});

export const FeatureBlockDetailsListItem = ({
  heading,
  subheading,
  preHeading,
  details,
  headingConfig,
  detailsConfig,
  headingClassName,
  detailsClassName,
  preHeadingClassName,
  className,
  entryId,
  ...rest
}) => {
  const defaultHeadingConfig = { min: 'ws-text-lg-medium', max: 'ws-text-2xl-medium' };
  const defaultDetailsConfig = { min: 'ws-text-lg', max: 'ws-text-2xl' };
  const finalHeadingConfig = headingConfig ?? defaultHeadingConfig;
  const finalDetailsConfig = detailsConfig ?? defaultDetailsConfig;

  const renderHeadingAnnotations = entryId
    ? toFieldPath({ objectId: entryId, fieldPath: 'itemHeading' })
    : {};
  const renderMarkdownAnnotations = entryId
    ? toFieldPath({ objectId: entryId, fieldPath: 'itemBody' })
    : {};

  return (
    <div className={className} {...toObjectId(entryId)} {...rest}>
      {preHeading && (
        <div className={preHeadingClassName} data-testid="point-item-preheading">
          {preHeading}
        </div>
      )}
      <FluidText
        type="h3"
        {...finalHeadingConfig}
        className={classNames(heading_, headingClassName)}
        {...renderHeadingAnnotations}
      >
        {heading}
      </FluidText>
      {subheading &&
        (isString(subheading) ? (
          <FluidText
            type="h4"
            className={subheading_}
            min="ws-text-xl-medium"
            max="ws-text-2xl-medium"
          >
            {subheading}
          </FluidText>
        ) : (
          <div className={subheading_}>{subheading}</div>
        ))}
      <FluidText
        type="markdown"
        markdownProps={{
          mode: MARKDOWN_MODES.textWithLists,
          renderers: RENDERER_CONFIG(finalDetailsConfig, detailsClassName),
        }}
        {...renderMarkdownAnnotations}
      >
        {details}
      </FluidText>
    </div>
  );
};

FeatureBlockDetailsListItem.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  preHeading: PropTypes.node,
  details: PropTypes.string,
  headingConfig: PropTypes.shape(FluidText.propTypes),
  detailsConfig: PropTypes.shape(FluidText.propTypes),
  headingClassName: PropTypes.string,
  detailsClassName: PropTypes.string,
  className: PropTypes.string,
  preHeadingClassName: PropTypes.string,
};
