import PropTypes from 'prop-types';
import React from 'react';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { isEquivalentLocale } from 'src/utils/localization';

/**
 * Determines whether the component will render in the current locale.
 * This is generally useful if you need to perform some logic but you are not within
 * a component to use the hook, so need to pass locales in manually.
 *
 * @param {Object} options - The options object.
 * @param {string} options.currentLocale - The current locale.
 * @param {string[]} options.localesList - The locales to check against the current locale.
 * @returns {boolean} - Returns `true` if the component should render in the current locale, otherwise `false`.
 */
export const willComponentRenderInLocale = ({ currentLocale, localesList }) => {
  return (localesList && localesList.some(_locale => isEquivalentLocale(_locale, currentLocale)));
};

/**
 * Determines whether the component will render in the current locale.
 * This is generally useful if you need to perform some logic in the parent of
 * the component (e.g. add / remove padding) to account for the component not rendering.
 *
 * @param {Object} componentProps - The props to be passed to the component
 * @param {string[]} componentProps.locales - The locales to check against the current locale.
 * @returns {boolean} - Returns `true` if the component should render in the current locale, otherwise `false`.
 */
export const useWillComponentRenderInCurrentLocale = ({ locales }) => {
  const { locale } = useTranslationContext();

  return willComponentRenderInLocale({ localesList: locales, currentLocale: locale });
};

/**
 * Higher-order component that renders the wrapped component if it should render in the current locale.
 * Assumes the wrapped component is passed a "locales" prop, which is an array of locales in which the component should render.
 *
 * @param {React.Component} WrappedComponent - The component to render when in a matching locale.
 * @returns {React.Component|null} The rendered WrappedComponent with the props passed if in a matching locale, or null if not.
 */
export const withLocaleCheckboxes = WrappedComponent => {
  const WithLocaleCheckboxes = ({ locales, ...props }) => {
    const shouldRenderComponent = useWillComponentRenderInCurrentLocale({ locales });
    return shouldRenderComponent ? <WrappedComponent {...props} /> : null;
  };
  return WithLocaleCheckboxes;
};

withLocaleCheckboxes.propTypes = {
  locales: PropTypes.arrayOf(PropTypes.string),
};
