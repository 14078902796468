'use client';

import React, { useCallback, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Accordion } from 'src/tapestry/core/accordion';
import { toFieldPath } from '@stackbit/annotations';
import { classNames } from 'src/utils/css';
import { ComponentContextProvider } from 'src/components/layout/ComponentContext';
import { useHeaderContext } from 'src/components/layout/Header';
import { Background } from 'src/components/common/Background';
import { colorSandBase } from 'src/styles/exports.module.scss';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { useUserAttributesContext } from 'src/components/contexts/UserAttributesContext';
import {
  DismissibleContextProvider,
  useDismissibleContext,
} from 'src/components/contexts/DismissibleContext';
import { COMPONENT_NAMES, EVENT_NAMES } from 'src/services/analytics-service';
import { FluidText } from 'src/components/common/FluidText';
import { Close } from 'src/components/common/Icons';
import {
  banner_,
  content_,
  textContentContainer_,
  textContentContainerCenter_,
  textContent_,
  closeButton_,
} from './NotificationBanner.module.scss';
import translationContent from './NotificationBanner.translations';

const NotificationBannerContent = ({ children, className, textAlign, background, entryId }) => {
  const { id, isOpen, closeHandler } = useDismissibleContext();
  const { appendTranslationKeys } = useTranslationContext();
  const { isMobileNavOpen, hasScrolledPastFirstSection } = useHeaderContext();
  const { isMobile } = useUserAttributesContext();
  const bannerRef = useRef();

  appendTranslationKeys(translationContent);
  const closeLabel = useTranslation('notification_banner::close');

  /*
   * Logic for handling the screen height available
   */

  const setBannerHeightCssVar = useCallback(() => {
    if (isOpen) {
      const bannerHeight = bannerRef.current.offsetHeight;
      document.documentElement.style.setProperty('--banner-height-px', `${bannerHeight}px`);
    } else {
      document.documentElement.style.setProperty('--banner-height-px', '0px');
    }
  }, [isOpen]);

  useEffect(() => {
    setBannerHeightCssVar();
    window.addEventListener('resize', setBannerHeightCssVar);

    // Reset banner height when navigating away
    return () => {
      document.documentElement.style.setProperty('--banner-height-px', '0px');
      window.removeEventListener('resize', setBannerHeightCssVar);
    };
  }, [setBannerHeightCssVar]);

  return (
    <Accordion
      id={`accordion-${id}`}
      isOpen={isMobileNavOpen || hasScrolledPastFirstSection ? false : isOpen}
    >
      <Background
        ref={bannerRef}
        as="div"
        data-testid="notification-banner"
        className={classNames(banner_, className)}
        background={background || colorSandBase}
      >
        <div className={classNames(content_)}>
          <div
            className={classNames(
              textContentContainer_,
              textAlign === 'center' && textContentContainerCenter_
            )}
          >
            <FluidText
              type="div"
              className={textContent_}
              min="ws-text-md-medium"
              max="ws-text-lg-medium"
              {...(entryId ? toFieldPath({ objectId: entryId, fieldPath: 'body' }) : {})}
            >
              {children}
            </FluidText>
          </div>
          <button type="button" className={classNames(closeButton_)} onClick={closeHandler}>
            <span className="visually-hidden">{closeLabel}</span>
            <Close size={isMobile ? 'sm' : 'md'} aria-hidden="true" />
          </button>
        </div>
      </Background>
    </Accordion>
  );
};

/**
 * The `<NotificationBanner>` component is a banner that can be used at the top
 * of any page, above the nav. It is dismissible - if the user has dismissed the
 * banner it will remain hidden.
 *
 * ## Use
 * To use this component, create an instance of `<NotificationBanner>` with a
 * unique, descriptive `id` and the desired content. Pass it as the `notificationBanner`
 * prop to `<PageLayout>`.
 *
 * **Note**: If your page is already passing a custom `<Header>` to `<PageLayout>`,
 * you need to pass the `notificationBanner` prop directly to your `<Header>` instead.
 */
export const NotificationBanner = ({ id, children, ...rest }) => {
  if (!id) return null;

  return (
    <ComponentContextProvider value={{ name: COMPONENT_NAMES.NOTIFICATION_BANNER }}>
      <DismissibleContextProvider
        id={id}
        componentName="NotificationBanner"
        displayedEvent={EVENT_NAMES.BANNER_DISPLAYED}
        dismissedEvent={EVENT_NAMES.BANNER_DISMISSED}
      >
        <NotificationBannerContent {...rest}>{children}</NotificationBannerContent>
      </DismissibleContextProvider>
    </ComponentContextProvider>
  );
};

NotificationBanner.propTypes = {
  /**
   * A unique ID. Used to track the dismissed state of the notification.
   */
  id: PropTypes.string.isRequired,

  /**
   * Notification content.
   */
  children: PropTypes.node.isRequired,

  /**
   * Default React behaviour.
   */
  className: PropTypes.string,

  /**
   * Optional text alignment. The property is only applied on small screens.
   */
  textAlign: PropTypes.oneOf(['center', 'default']),

  /**
   * Optional background to use instead of the default. Any valid CSS background
   * string is acceptable.
   */
  background: PropTypes.string,
  entryId: PropTypes.string,
};
